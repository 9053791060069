import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup } from '@mui/material';

export default React.memo(
    function AddDialog({ selected, open, onClose, onAddMemberHandler }: any) {

        const [startPosition, setStartPosition] = useState('child');

        const handleStartPositionChange = (
            event: React.MouseEvent<HTMLElement>,
            newStartPosition: string,
        ) => {

            if (newStartPosition !== null) {
                setStartPosition(newStartPosition);
            }

        };

        const addMemberHandler = () => {
            onAddMemberHandler(startPosition, age === 'male');
        };

        const [age, setAge] = React.useState('female');

        const handleChange = (
            event: React.MouseEvent<HTMLElement>,
            newStartPosition: string) => {
            if (newStartPosition !== null) {
                setAge(newStartPosition);
            }

        };

        return (
            <template>
                {open && (<Dialog open={open} onClose={() => onClose()}>
                    <DialogTitle>Přidat člena</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}>
                            <h4>{`nový člen je k osobě ${selected.id}`}</h4>
                            <ToggleButtonGroup
                                color="primary"
                                value={startPosition}
                                exclusive
                                onChange={handleStartPositionChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="child">Dítě</ToggleButton>
                                <ToggleButton disabled={selected.spouses.length === 1} value="partner">Partner</ToggleButton>
                                <ToggleButton disabled={selected.parents.length === 2} value="parent">Rodič</ToggleButton>
                            </ToggleButtonGroup>

                            <h4>{`Pohlaví`}</h4>

                            <ToggleButtonGroup
                                color="primary"
                                value={age}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="female">žena</ToggleButton>
                                <ToggleButton value="male">muž</ToggleButton>
                            </ToggleButtonGroup>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()}>Zrušit</Button>
                        <Button color='primary' onClick={() => addMemberHandler()}>Přidat</Button>
                    </DialogActions>
                </Dialog>)}
            </template>
        );
    },
);
