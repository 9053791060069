import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import type { Node } from "relatives-tree/lib/types";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBnNlYznQkgvKvO0RDmqsVE-ZlaTihg3ps",
  authDomain: "rodina-malat.firebaseapp.com",
  databaseURL:
    "https://rodina-malat-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rodina-malat",
  storageBucket: "rodina-malat.appspot.com",
  messagingSenderId: "158091339708",
  appId: "1:158091339708:web:bea7356de4ca08b5c7edb9",
  measurementId: "G-XV13ZLQVBB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

const dataRef = ref(database, "/");

const auth = getAuth(app);

const getData = async () => {
  try {
    await signOut(auth);
    if (!auth.currentUser) {
      const PASSWORD = prompt("Heslo", "");
      if (!PASSWORD) {
        document.querySelector("body")!.innerHTML = "";
        document.write("Nesprávné heslo");
        return;
      }

      await signInWithEmailAndPassword(auth, "t@gmail.com", PASSWORD!);
    }
    const snapshot = await get(dataRef);
    const data = snapshot.val();
    return data.root.map((x: Node) => {
      return {
        ...x,
        spouses: x.spouses || [],
        parents: x.parents || [],
        children: x.children || [],
        siblings: x.siblings || [],
      };
    });
  } catch (e) {
    document.querySelector("body")!.innerHTML = "";
    document.write("Nesprávné heslo");
    return;
  }
};

const saveData = async (data: Node[]) => {
  set(dataRef, { root: data });
};

export default { getData, saveData };
