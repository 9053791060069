import React, { useMemo, useState, useCallback, useEffect } from 'react';
import type { Node, ExtNode } from 'relatives-tree/lib/types';
import ReactFamilyTree from 'react-family-tree';
import { PinchZoomPan } from '../PinchZoomPan/PinchZoomPan';
import { FamilyNode } from '../FamilyNode/FamilyNode';
import { NodeDetails } from '../NodeDetails/NodeDetails';
import { NODE_WIDTH, NODE_HEIGHT, SOURCES } from '../const';
import { getNodeStyle } from './utils';
import { RelType } from 'relatives-tree/lib/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Gender } from 'relatives-tree/lib/types';

import css from './App.module.css';
import { Fab, Menu, MenuItem } from '@mui/material';
import AddDialog from '../AddDialog/AddDialog';
import firebase from '../../firebase';

export default React.memo(
  function App() {
    const [nodes, setNodes] = useState([] as any[]);

    const firstNodeId = useMemo(() => nodes.length ? nodes.find((x: any) => x.id === 'matka').id : '', [nodes]);
    const [rootId, setRootId] = useState(firstNodeId);

    const [selectId, setSelectId] = useState<string>();
    const [hoverId, setHoverId] = useState<string>();

    const [openDialog, setOpenDialog] = useState(false);

    const resetRootHandler = useCallback(() => setRootId(firstNodeId), [firstNodeId]);

    const onSelect = (id: string) => {
      setSelectId(id)
    }

    const selected = useMemo(() => (
      nodes.find(item => item.id === selectId)
    ), [nodes, selectId]);

    const onAddMemberHandler = (relationship: string, male: boolean) => {
      let name = prompt('Zadejte nové jméno')
      if (!name) return
      const numberOfSameNames = nodes.filter(item => item.id === name).length
      if (numberOfSameNames > 0) name = `${name} (${numberOfSameNames})`
      const addingMember = nodes.find(item => item.id === selectId);
      let newMember = {} as Node
      if (relationship === 'child') {
        newMember = {
          id: `${name}`,
          gender: male ? Gender.male : Gender.female,
          parents: addingMember.spouses.length ? [{ id: `${addingMember?.id}`, type: RelType.blood }, { id: `${addingMember?.spouses[0].id}`, type: RelType.blood }] : [{ id: `${addingMember?.id}`, type: RelType.blood }],
          spouses: [],
          children: [],
          siblings: [...selected.children],
        } as Node;
      } else if (relationship === 'partner') {
        newMember = {
          id: `${name}`,
          gender: male ? Gender.male : Gender.female,
          parents: [],
          spouses: [{ id: `${addingMember?.id}`, type: RelType.blood }],
          children: [...selected.children],
          siblings: [],
        } as Node;
      }
      else if (relationship === 'parent') {
        newMember = {
          id: `${name}`,
          gender: male ? Gender.male : Gender.female,
          parents: [],
          spouses: selected.parents.length ? [{ id: `${selected?.parents[0].id}`, type: RelType.blood }] : [],
          children: [{ id: `${selectId}`, type: RelType.blood }, ...selected.siblings],
          siblings: [],
        } as Node;
      }

      // Use the callback form of setNodes to ensure you have the updated state
      setNodes(prevNodes => {
        const newMap = prevNodes.map(item => {
          if (relationship === 'child') {
            if (newMember.parents.flatMap(x => x.id).includes(item.id))
              return { ...item, children: [...item.children, { id: `${newMember.id}`, type: RelType.blood }] }
            else if (newMember.siblings.flatMap(x => x.id).includes(item.id))
              return { ...item, siblings: [...item.siblings, { id: `${newMember.id}`, type: RelType.blood }] }
          }
          else if (relationship === 'partner') {
            if (newMember.spouses.flatMap(x => x.id).includes(item.id))
              return { ...item, children: [...selected.children], spouses: [...item.spouses, { id: `${newMember.id}` }] }
            else if (newMember.children.flatMap(x => x.id).includes(item.id))
              return { ...item, parents: [...item.parents, { id: `${newMember.id}`, type: RelType.blood }] }
          }
          else if (relationship === 'parent') {
            if (newMember.children.flatMap(x => x.id).includes(item.id))
              return { ...item, parents: [...item.parents, { id: `${newMember.id}`, type: RelType.blood }] }
            else if (newMember.spouses.flatMap(x => x.id).includes(item.id))
              return { ...item, spouses: [...item.spouses, { id: `${newMember.id}`, type: RelType.blood }] }
          }

          return item

        })

        return [...newMap, newMember];
      });
      setOpenDialog(false)
      handleCloseMenu()
    };

    const removeUser = () => {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(`Opravdu chcete odstranit osobu ${selectId} ?`)) return
      setNodes(prevNodes => {
        const newMap = prevNodes.map(item => {
          if (item.id === selectId) {
            return null
          }
          if (item.parents) {
            item.parents = item.parents.filter((parent: any) => {
              if (parent.id !== selectId) {
                return item
              }
            })
          }
          if (item.spouses) {
            item.spouses = item.spouses.filter((spouse: any) => {
              if (spouse.id !== selectId) {
                return item
              }
            })
          }
          if (item.children) {
            item.children = item.children.filter((child: any) => {
              if (child.id !== selectId) {
                return item
              }
            })
          }
          if (item.siblings) {
            item.siblings = item.siblings.filter((sibling: any) => {
              if (sibling.id !== selectId) {
                return item
              }
            })
          }
          return item
        }
        ).filter((item: any) => item !== null)

        return [...newMap];
      });
      handleCloseMenu()
    }

    const test = async () => {
      const data = await firebase.getData()
      console.log(data)
      setNodes([...data])
      setRootId(data.find((x: any) => x.id === 'matka').id)
    }

    useEffect(() => {
      test()
    }, [])

    const renameUser = () => {
      let name = prompt('Zadejte nové jméno', selectId)
      if (!name) return
      const numberOfSameNames = nodes.filter(item => item.id === name).length
      if (numberOfSameNames > 0) name = `${name} (${numberOfSameNames})`
      const mapped = nodes.map(item => {
        if (item.id === selectId) {
          item.id = name
        }
        if (item.parents) {
          item.parents.map((parent: any) => {
            if (parent.id === selectId) {
              parent.id = name
            }
          })
        }
        if (item.spouses) {
          item.spouses.map((spouse: any) => {
            if (spouse.id === selectId) {
              spouse.id = name
            }
          })
        }
        if (item.children) {
          item.children.map((child: any) => {
            if (child.id === selectId) {
              child.id = name
            }
          })
        }
        if (item.siblings) {
          item.siblings.map((sibling: any) => {
            if (sibling.id === selectId) {
              sibling.id = name
            }
          })
        }
        return item
      })
      setNodes([...mapped])
      handleCloseMenu()
    }

    const save = () => {
      firebase.saveData(nodes)
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    return (
      <div className={css.root}>
        <header className={css.header}>
          <h1 className={css.title}>
            Malátovi
          </h1>
        </header>
        {nodes.length > 0 && (
          <PinchZoomPan min={0.5} max={2.5} captureWheel className={css.wrapper}>
            <ReactFamilyTree
              nodes={nodes}
              rootId={rootId}
              width={NODE_WIDTH}
              height={NODE_HEIGHT}
              className={css.tree}
              renderNode={(node: Readonly<ExtNode>) => (
                <FamilyNode
                  key={node.id}
                  node={node}
                  isRoot={node.id === rootId}
                  isHover={node.id === hoverId}
                  onClick={onSelect}
                  onSubClick={setRootId}
                  style={getNodeStyle(node)}
                />
              )}
            />
          </PinchZoomPan>
        )}
        {rootId !== firstNodeId && (
          <button className={css.reset} onClick={resetRootHandler}>
            Reset
          </button>
        )}
        {selected && (
          <NodeDetails
            node={selected}
            className={css.details}
            onSelect={setSelectId}
            onHover={setHoverId}
            onClear={() => setHoverId(undefined)}
          />
        )}
        {openDialog && (<AddDialog selected={selected} open={openDialog} onClose={() => setOpenDialog(false)} onAddMemberHandler={(relationship: string, male: boolean) => onAddMemberHandler(relationship, male)} />)}
        {<footer>
          {!selectId && (<div style={{
            position: 'absolute',
            bottom: 30,
            right: 50, color: 'darkred'
          }}>
            Je nutné vybrat osobu
          </div>)}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => setOpenDialog(true)}>Přidat člena k {selectId}</MenuItem>
            <MenuItem onClick={renameUser}>Upravit {selectId}</MenuItem>
            <MenuItem onClick={removeUser}>Odstranit {selectId}</MenuItem>
          </Menu>
          <Fab id="basic-button" variant="extended" aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined} onClick={handleClickMenu} disabled={!selectId} sx={{
              position: 'absolute',
              bottom: 50,
              right: 50,
            }} aria-label={'Pridat'} color={'primary'}>
            Akce
          </Fab>
          {<Fab variant="extended" onClick={() => save()} sx={{
            position: 'absolute',
            bottom: 50,
            left: 50,
          }} aria-label={'Odstranit'} color={'success'}>
            Uložit
          </Fab>}
        </footer>}
      </div>
    );
  },
);
